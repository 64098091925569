import { ref } from 'vue';
import { useStoryblokApi } from '@storyblok/vue';
import { useRuntimeConfig } from '#imports';

export interface StoryblokStory {
  id: number;
  name: string;
  full_slug: string;
  created_at: string;
  published_at: string;
  content: StoryblokStory[];
}

export interface StoryblokRequestConfig {
  starts_with: string;
  resolve_relations?: string[];
  per_page?: number;
  is_startpage: boolean;
}

export interface PaginatedRequestConfig extends StoryblokRequestConfig {
  page: number;
  per_page: number;
}

export const API_CONFIGS = {
  activities: {
    starts_with: 'activities',
    is_startpage: false,
    per_page: 100,
    resolve_relations: ['Activity.locations', 'Activity.addons'],
  },
  packages: {
    starts_with: 'packages',
    is_startpage: false,
    per_page: 100,
    resolve_relations: ['Package.locations', 'Package.activities'],
  },
  locations: {
    starts_with: 'locations',
    is_startpage: false,
  },
  events: {
    starts_with: 'events',
    is_startpage: false,
    per_page: 100,
    resolve_relations: ['Event.locations', 'Event.activities'],
  },
  gifts: {
    starts_with: 'gifts',
    is_startpage: false,
    per_page: 100,
    resolve_relations: ['Gift.locations', 'Gift.activities'],
  },
  offers: {
    starts_with: 'offers',
    is_startpage: false,
    per_page: 100,
    resolve_relations: ['Offer.activities', 'Offer.locations'],
  },
  groups: {
    starts_with: 'groups',
    is_startpage: false,
    per_page: 100,
    resolve_relations: ['Group.locations'],
  },
  changelog: {
    starts_with: 'changelog',
    is_startpage: false,
  },
  press: {
    starts_with: 'press',
    is_startpage: false,
  },
  blog: {
    starts_with: 'blog',
    is_startpage: false,
  },
  addons: {
    starts_with: 'addons',
    is_startpage: false,
    per_page: 100,
  },
} as const;

export const useStoryblokFetch = () => {
  const isPreview = useRuntimeConfig().public.NODE_ENV !== 'production';
  const storyblokApi = useStoryblokApi();
  const error = ref<string | null>(null);
  const totalItems = ref<number>(0);

  const fetchStories = async <T extends StoryblokStory>(
    key: {
      type: string;
      default: '';
    },
    config: StoryblokRequestConfig
  ) => {
    try {
      const { data } = await storyblokApi.get('cdn/stories', {
        version: isPreview ? 'draft' : 'published',
        ...config,
      });

      return data.stories as T[];
    } catch (err) {
      error.value =
        err instanceof Error ? err.message : `Error fetching ${key}`;
      throw error;
    }
  };

  const fetchPaginatedStories = async <T extends StoryblokStory>(
    key: {
      type: string;
      default: '';
    },
    config: PaginatedRequestConfig
  ) => {
    try {
      const response = await storyblokApi.get(
        'cdn/stories',
        {
          version: isPreview ? 'draft' : 'published',
          ...config,
        },
        {
          resolveWithHeadersAndData: true,
        }
      );

      totalItems.value = parseInt(response.headers?.total || '0');
      return response.data.stories as T[];
    } catch (err) {
      error.value =
        err instanceof Error ? err.message : `Error fetching ${key}`;
      throw error;
    }
  };

  const fetchStory = async <T extends StoryblokStory>(slug: string) => {
    const { data, error } = await useAsyncData(`story-${slug}`, async () => {
      try {
        const { data } = await storyblokApi.get(
          `cdn/stories/${slug.replace(/\/$/, '')}`,
          {
            version: isPreview ? 'draft' : 'published',
            resolve_relations: [
              'Activity.locations',
              'Group.locations',
              'Group.cta_banner',
              'ContentTopActivities.activities',
              'Blog.locations',
              'Blog.activities',
              'Wizard.group',
              'Wizard.locations',
              'Wizard.activities',
            ],
            resolve_links: 'url',
          }
        );

        if (!data?.story) {
          throw createError({
            statusCode: 404,
            statusMessage: 'Page could not be found',
          });
        }

        return data.story as T;
      } catch (err) {
        console.error(`Error fetching story for ${slug}:`, err);
        throw createError({
          statusCode: 404,
          statusMessage: 'Page could not be found',
        });
      }
    });

    if (error.value) {
      throw error.value;
    }

    return { data, error };
  };

  return {
    fetchStories,
    fetchPaginatedStories,
    fetchStory,
    error,
    totalItems,
  };
};

export const useStoryblokClient = () => {
  const { fetchStories, fetchPaginatedStories, fetchStory, error, totalItems } =
    useStoryblokFetch();
  const isLoading = ref<boolean>(false);

  const pad = (num: number) => (num < 10 ? `0${num}` : num);
  const currentDate = new Date();
  const formattedDate = `${currentDate.getFullYear()}-${pad(currentDate.getMonth() + 1)}-${pad(
    currentDate.getDate()
  )} ${pad(currentDate.getHours())}:${pad(currentDate.getMinutes())}`;

  // Type interfaces remain the same
  interface Activity extends StoryblokStory {
    content: {
      locations: StoryblokStory[];
      addons: StoryblokStory[];
    };
  }

  interface Package extends StoryblokStory {
    content: {
      locations: StoryblokStory[];
      activities: StoryblokStory[];
    };
  }

  interface Event extends StoryblokStory {
    content: {
      locations: StoryblokStory[];
      activities: StoryblokStory[];
    };
  }

  interface Gift extends StoryblokStory {
    content: {
      locations: StoryblokStory[];
      activities: StoryblokStory[];
    };
  }

  interface Offer extends StoryblokStory {
    content: {
      activities: StoryblokStory[];
      locations: StoryblokStory[];
    };
  }

  interface Group extends StoryblokStory {
    content: {
      locations: StoryblokStory[];
    };
  }

  const fetchWithLoading = async <T>(fetchFn: () => Promise<T>): Promise<T> => {
    isLoading.value = true;
    try {
      return await fetchFn();
    } finally {
      isLoading.value = false;
    }
  };

  // Fetch functions remain the same but without manual caching
  const fetchActivities = () =>
    fetchWithLoading(() =>
      fetchStories<Activity>('activities', API_CONFIGS.activities)
    );

  const fetchPackages = () =>
    fetchWithLoading(() =>
      fetchStories<Package>('packages', API_CONFIGS.packages)
    );

  const fetchLocations = () =>
    fetchWithLoading(() =>
      fetchStories<StoryblokStory>('locations', API_CONFIGS.locations)
    );

  const fetchEvents = () =>
    fetchWithLoading(() => fetchStories<Event>('events', API_CONFIGS.events));

  const fetchGifts = () =>
    fetchWithLoading(() => fetchStories<Gift>('gifts', API_CONFIGS.gifts));

  const fetchOffers = () =>
    fetchWithLoading(() => fetchStories<Offer>('offers', API_CONFIGS.offers));

  const fetchGroups = () =>
    fetchWithLoading(() => fetchStories<Group>('groups', API_CONFIGS.groups));

  const fetchChangelog = (
    page: {
      type: number;
      default: 0;
    },
    perPage: number
  ) =>
    fetchWithLoading(() =>
      fetchPaginatedStories<StoryblokStory>('changelog', {
        ...API_CONFIGS.changelog,
        page,
        per_page: perPage,
        filter_query: {
          date: {
            lt_date: formattedDate,
          },
        },
      })
    );

  const fetchPress = (
    page: {
      type: number;
      default: 0;
    },
    perPage: number
  ) =>
    fetchWithLoading(() =>
      fetchPaginatedStories<StoryblokStory>('press', {
        ...API_CONFIGS.press,
        page,
        per_page: perPage,
        filter_query: {
          date: {
            lt_date: formattedDate,
          },
        },
      })
    );

  const fetchBlog = (
    page: {
      type: number;
      default: 0;
    },
    perPage: number
  ) =>
    fetchWithLoading(() =>
      fetchPaginatedStories<StoryblokStory>('blog', {
        ...API_CONFIGS.blog,
        page,
        per_page: perPage,
        filter_query: {
          date: {
            lt_date: formattedDate,
          },
        },
      })
    );

  const fetchAddons = () =>
    fetchWithLoading(() =>
      fetchStories<StoryblokStory>('addons', API_CONFIGS.addons)
    );

  return {
    fetchActivities,
    fetchPackages,
    fetchLocations,
    fetchEvents,
    fetchGifts,
    fetchOffers,
    fetchGroups,
    fetchChangelog,
    fetchPress,
    fetchBlog,
    fetchAddons,
    fetchStory,
    totalItems,
    isLoading,
    error,
  };
};
